import React, { useContext, useEffect, useState } from "react";
import "./TrainingPage.scss";
import DataService from "../../API/DataService";
import { useFetching } from "../../hooks/useFetching";
import Workouts from "../../components/Workouts/Workouts";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import TrainingTabs from "../../components/TrainingTabs/TrainingTabs";
import TrainingTabsMobile from "../../components/TrainingTabsMobile/TrainingTabsMobile";
import FollowingBtn from '../../components/FollowingBtn/FollowingBtn';

const TrainingPage = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const { eventStore } = useContext(Context);

    const [moreInfoFlag, setMoreInfoFlag] = useState(false);
    const [modalWorkout, setModalWorkout] = useState({});

    const activate = (index) => {
        // console.log(activeTabIndex);
        setActiveTabIndex(index);
    };

    const coord = (event) => {
        console.log(event.target);
    };

    const openModal = (type) => {
        console.log(type);
        setModalWorkout(type);
        setMoreInfoFlag(true);
    };

    const nameMode = (name) => {
        switch (name) {
            case "ALEKSEEVA":
                return "СК Алексеева";
            case "SK_VIZOV":
                return "СК Вызов";
            case "DINAMIT":
                return "СК Динамит";
            default:
                break;
        }
    };

    return (
        <>
            <div
                onClick={(event) => eventStore.closePlaers(event)}
                className="trainingPage"
            >
                <div
                    className={
                        eventStore.flagErrorTraining
                            ? "modal-window active"
                            : "modal-window"
                    }
                >
                    <div
                        onClick={() => eventStore.setFlagErrorTraining(false)}
                        className="modal-window__close"
                    >
                        <span></span>
                        <span></span>
                    </div>
                    <div className="modal-window__title">
                        {eventStore.errorTraining}
                    </div>
                </div>
                <div
                    onClick={() => setMoreInfoFlag(false)}
                    className={
                        moreInfoFlag
                            ? "trainingPage__bg active"
                            : "trainingPage__bg"
                    }
                >
                    <div className="trainingPage__modal modal-more-info">
                        {modalWorkout.type == "Игровая" ? (
                            <p>
                                Игровая для любого уровня. Но вы должны знать
                                правила, и играть с тренером не менее полугода.
                                Распределение идет по уровням. Играете своими воланами, организаторы воланы не предоставляют.
                                <br />
                                <br />
                                Стоимость игровой - 800 рублей.
                                <br />
                                <br />
                                Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                    <a href="#">
                                        5534 2000 3456 1456 
                                    </a>
                            </p>
                        ) : modalWorkout.type == "Тактическая игровая" ? (
                            <p>
                                Тактическая игровая.
                                <br/>
                                <br/>
                                Тренер участвует в игре, либо наблюдает за игрой
                                со стороны. Фиксирует ошибки и в конце розыгрыша
                                комментирует их. Создаёт похожие игровые
                                ситуации, чтобы игрок учился правильно
                                реагировать по месту, выбирать удар, видеть
                                свободную зону. Корректирует перемещения в
                                парном взаимодействии.
                                <br/>
                                <br/>
                                Данные игровые для продвинутых игроков, от
                                уровня ЕD.
                                <br/>
                                Основная цель - научиться применять в игре
                                навыки приобретённые на тренировке.
                                <br/>
                                Стоимость в филиале{" "}
                                {nameMode(modalWorkout.sportComplex)}:
                                {modalWorkout.sportComplex === 'DINAMIT' ? (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 2600 рублей<br/>
                                        2 человека 1 час — 3200 рублей<br/>
                                        Групповые 3 - 6 человек 1,5 часа — 1100 рублей<br/>
                                    </>
                                ) : (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 3000 рублей<br/>
                                        2 человека 1 час — 3600 рублей<br/>
                                        Групповые 3 - 4 человека 1,5 часа — 1450 рублей<br/>
                                        5 человек 1,5 часа — 1350 рублей<br/>
                                        6 человек 1,5 часа — 1250 рублей<br/>

                                    </>
                                )}

                                <br/>
                                <br/>
                                Минимальный кворум для тренировки – 3 человека
                                По всем вопросам обращайтесь
                                <a href="tel:+79030975817">+7(903) 097 58 17</a>
                                <br/>
                                <br/>
                                Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                <a href="#">
                                    5534 2000 3456 1456
                                </a>
                            </p>
                        ) : modalWorkout.type ==
                          "Тренировка для продолжающих" ? (
                            <p>
                                Тренировки для продвинутых игроков.
                                <br />
                                <br />
                                Для кого подходят ?<br />
                                <br />
                                Для игроков уровня, начиная от D.
                                <br />
                                Стоимость в филиале{" "}
                                {nameMode(modalWorkout.sportComplex)}:
                                {modalWorkout.sportComplex === 'DINAMIT' ? (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 2600 рублей<br/>
                                        2 человека 1 час — 3200 рублей<br/>
                                        Групповые 3 - 6 человек 1,5 часа — 1100 рублей<br/>
                                    </>
                                ) : (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 3000 рублей<br/>
                                        2 человека 1 час — 3600 рублей<br/>
                                        Групповые 3 - 4 человека 1,5 часа — 1450 рублей<br/>
                                        5 человек 1,5 часа — 1350 рублей<br/>
                                        6 человек 1,5 часа — 1250 рублей<br/>

                                    </>
                                )}

                                <br />
                                <br />
                                Минимальный кворум для тренировки – 3 человека
                                По всем вопросам обращайтесь
                                <a href="tel:+79030975817">+7(903) 097 58 17</a>
                                <br />
                                <br />
                                Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                    <a href="#">
                                        5534 2000 3456 1456 
                                    </a>
                            </p>
                        ) :  modalWorkout.type ==
                        "Мастер+" ? (
                          <p>
                              Приглашаем поучаствовать в новом формате - Мастер+.
                              <br />
                              <br />
                              Отличная возможность поиграть игры с более сильным партнёром и противниками. Играет тренер и ученик.
                              <br />
                              Стоимость участия - 2700 рублей плюс ваши перьевые воланы.
                              <br />
                              <br />
                              По всем вопросам обращайтесь
                              <a href="tel:+79030975817">+7(903) 097 58 17</a>
                              <br />
                              <br />
                              Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                  <a href="#">
                                      5534 2000 3456 1456 
                                  </a>
                          </p>
                      ) : (
                            <p>
                                Тренировки для начинающих.
                                <br />
                                <br />
                                Для кого подходят ?<br />
                                <br />
                                Если вы не играли в спортивный бадминтон, либо
                                пробовали, но не знаете правил, не владеете
                                ударами и хотите научиться. А также для игроков
                                занимающихся менее полугода. Уровень EF.
                                <br />
                                <br />
                                Стоимость в филиале{" "}
                                {nameMode(modalWorkout.sportComplex)}:
                                {modalWorkout.sportComplex === 'DINAMIT' ? (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 2600 рублей<br/>
                                        2 человека 1 час — 3200 рублей<br/>
                                        Групповые 3 - 6 человек 1,5 часа — 1100 рублей<br/>
                                    </>
                                ) : (
                                    <>
                                        <br/><br/>
                                        1 человек 1 час — 3000 рублей<br/>
                                        2 человека 1 час — 3600 рублей<br/>
                                        Групповые 3 - 4 человека 1,5 часа — 1450 рублей<br/>
                                        5 человек 1,5 часа — 1350 рублей<br/>
                                        6 человек 1,5 часа — 1250 рублей<br/>

                                    </>
                                )}

                                <br />
                                <br />
                                Минимальный кворум для тренировки – 3 человека
                                По всем вопросам обращайтесь
                                <a href="tel:+79030975817">+7(903) 097 58 17</a>
                                <br />
                                <br />
                                Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                    <a href="#">
                                        5534 2000 3456 1456 
                                    </a>
                            </p>
                        )}
                    </div>
                </div>
                <div
                    onClick={() => eventStore.setFlagOpenModalSale(false)}
                    className={
                        eventStore.flagOpenModalSale
                            ? "trainingPage__bg active"
                            : "trainingPage__bg"
                    }
                >
                    <div className="trainingPage__modal modal-more-info">
                        Что нужно на первую тренировку 🏸?
                        <br />
                        <br />
                        Вам понадобятся:
                        <br />
                        Форма. Свободная, не сковывающая движения. Это может
                        быть спортивная футболка и шорты/юбка/тренировочные
                        штаны.
                        <br />
                        <br />
                        Кроссовки. Для первых тренировок не обязательно иметь
                        бадминтонные кроссовки, достаточно чтобы они были на
                        светлой подошве, не скользящие. Подойдут зальные
                        кроссовки.
                        <br />
                        <br />
                        Ракетка. Если у вас нет ракетки, можно взять у тренера в
                        аренду. В последствии приобрете подходящую своему
                        уровню, стилю игры. В клубе есть услуга - подбор
                        ракетки.
                        <br />
                        <br />
                        Бутылка воды.
                        <br />
                        <br />
                        Воланы - на тренировку не нужны. Сам тренировочный
                        процесс тренер обеспечивает перьевыми воланами.
                        <br />
                        <br />
                        Позитивный настрой и желание научиться играть в
                        бадминтон - берите с собой обязательно!
                        <br />
                        <br />
                        По всем вопросам обращайтесь
                        <a href="tel:+79030975817">+7(903) 097 58 17</a>
                        <br />
                        <br />
                        Оплата происходит переводом на корпоративную карту Тинькофф:{" "}
                                    <a href="#">
                                        5534 2000 3456 1456 
                                    </a>
                    </div>
                </div>

                <div className="container">
                    <div className="trainingPage__title">Расписание</div>

                    <TrainingTabs
                        openModal={(type) => openModal(type)}
                    />
                    <TrainingTabsMobile
                        openModal={(type) => openModal(type)}
                    />
                </div>
                <ul className="people-actions__list">
                    <div className="people-actions__exit">
                        <span></span>
                        <span></span>
                    </div>

                    {eventStore.players.map((sportsmen, index) => (
                        <li
                            key={index}
                            className="people-actions__item item-people"
                        >
                            {/* <div className="item-people__img"></div> */}
                            <div className="item-people__name">
                                {sportsmen.firstName + " " + sportsmen.lastName}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default observer(TrainingPage);
