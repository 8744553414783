import React, {useEffect, useState} from 'react';
import DataService from '../../../API/DataService';
import {useFetching} from '../../../hooks/useFetching';
import User from '../User';

const InfoAllUser = () => {
  const [users, setUsers] = useState([]);
  const [exportFile, setExportFile] = useState(null)

  const [fetchingAllUsers, isLoadingAllUsers, errorAllUsers] =
    useFetching(async () => {
      const response = await DataService.getAllUsers();
      setUsers(response.data);
    });
  const [fetchingExportFile, isLoadingExportFile, errorExportFile] =
    useFetching(async () => {
      try {
        const response = await DataService.getExportFile();
        if (response.data instanceof Blob) {
          const downloadUrl = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');

          link.href = downloadUrl;
          link.download = 'users.xlsx';
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
        }
      } catch (error) {
        console.error('Ошибка при скачивании файла:', error);
      }
    });

  useEffect(() => {
    fetchingAllUsers();
  }, []);

  return (
    <div className="admin__main">
      <div className="admin__btns">
        <button style={{border: 'none'}} className="admin__create-btn" onClick={() => fetchingExportFile()}>Скачать файл</button>
      </div>
      <div className="admin__users users-admin">
        {users.map((user, index) => (
          <User user={user}/>
        ))}
      </div>
    </div>
  );
};

export default InfoAllUser;